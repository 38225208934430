<template>
  <div class="Conocimiento">
    <section class="pt240 pb160 pt-xs-80 pb-xs-80 image-bg page-title">
      <div
        class="background-image-holder"
        :class="diplomado.imagen_dip ? 'has-image': ''"
      >
        <img
          v-if="diplomado.imagen_dip"
          :alt="diplomado.nombre_dip"
          class="background-image"
          :src="$baseUrl+diplomado.imagen_dip"
        />
        <img
          v-else
          :alt="diplomado.nombre_dip"
          class="background-image"
          src="@/assets/gradientes/Sky.jpeg"
        />
      </div>
    </section>
    <section class="contained-page p0">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-md-offset-1 pt64 pb64 bg-secondary">
            <h2
              class="uppercase text-center mb64 mb-xs-24"
              v-text="diplomado.nombre_dip"
            />
            <div class="col-md-8 col-md-offset-2">
              <div class="mb40 mb-xs-24">
                <div
                  class="mb32"
                  v-html="diplomado.contenido_web_dip"
                />
              </div>
              <div
                class="bg-white pt40 pb16 overflow-hidden"
                v-if="diplomado.estatus_dip==='abierto'"
              >
                <div class="col-sm-8 p32">
                  <p>
                    Inscribite ahora al {{diplomado.tipo_dip}} <strong>{{diplomado.nombre_dip}}</strong>
                  </p>
                </div>

                <div class="col-sm-4">
                  <router-link
                    class="btn btn-filled mb0"
                    v-text="'Inscribirme'"
                    :to="'/inscripcion/'+diplomado.id+'/'+diplomado.url_dip"
                  />
                </div>
              </div><br><br>
              <div class="bg-white pt40 pb16 overflow-hidden">
                <div
                  class="col-sm-8 p32"
                  v-if="modulos.length<1"
                >
                  <p>
                    Si ya estas inscrito, desde aquí podrás ingrear y ver el contenido del diplomado
                  </p>
                </div>

                <div
                  class="col-sm-4"
                  v-if="modulos.length<1"
                >
                  <button
                    v-if="!showFormSesion"
                    type="button"
                    class="btn btn-filled mb0"
                    @click="showFormSesion = true"
                  >
                    Ver contenido
                  </button>
                </div>
                <div
                  class="col-sm-12"
                  v-if="showFormSesion && modulos.length<1"
                >
                  <input
                    v-model="usr.tel"
                    type="text"
                    name="tel"
                    placeholder="Tu teléfono"
                    class="transparent validate-required is-input"
                    :disabled="cargando"
                  >
                  <input
                    v-model="usr.mail"
                    type="email"
                    name="email"
                    placeholder="Tu correo electrónico"
                    class="transparent validate-required is-input"
                    :disabled="cargando"
                  >
                  <button
                    type="button"
                    class="btn btn-filled mb0"
                    @click="_doLoginAlumno"
                    :disabled="cargando"
                  >
                    Ver contenido
                  </button>
                  <div
                    class="lds-ring"
                    v-if="cargando"
                  >
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <div
                  class="cols-sm-12 p32"
                  v-for="(modulo,idxm) in modulos"
                  :key="idxm"
                >
                  <h3>Módulo {{modulo.numero_dmo}}, "{{modulo.nombre_dmo}}"</h3>
                  <div v-html="modulo.descripcion_dmo"></div>
                  <div v-html="modulo.contenido_dmo" />
                  <div v-if="modulo.documentos.length>=1">
                    <br>
                    <h5>Documentos</h5>
                    <div
                      v-for="(docto,idxd) in modulo.documentos"
                      :key="idxd"
                    >
                      <router-link
                        class="is-link"
                        :to="docto.tipo_dmd ==='enlace' ? docto.enlance : docto.archivo_dmd"
                      >
                        <span>{{docto.tipo_dmd ==='enlace' ? 'Ver enlace':'Descargar archivo'}} {{docto.nombre_dmd}}</span>
                      </router-link>
                    </div>
                  </div>
                  <br>
                  <hr>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
export default {
  name: "DiplomadoSingle",
  mixins: [forms],
  data() {
    return {
      showFormSesion: false,
      showContenido: false,
      usr: {
        tel: "123",
        mail: "123@123.com",
      },
      diplomado: {
        contenido_web_dip: null,
        descripcion_dip: null,
        estatus_dip: null,
        id: null,
        id_dip: null,
        imagen_dip: null,
        nombre_dip: null,
        tipo_dip: null,
        url_dip: null,
      },
      modulos: [],
    };
  },
  methods: {
    _getDiplomado() {
      this._getThings("diplomado/" + this.$route.params.id, "diplomado");
    },
    async _doLoginAlumno() {
      this.cargando = true;
      try {
        const data = {
          tel: this.usr.tel,
          mail: this.usr.mail,
          diplomado: this.diplomado.id_dip,
        };
        const { status, body } = await this.$http.post("login", data);
        if (status === 200 && "modulos" in body) {
          this.modulos = body.modulos;
          this.showContenido = true;
        } else {
          this.showContenido = false;
        }
        this.cargando = false;
      } catch (err) {
        this.cargando = false;
        this.showContenido = false;
      }
    },
  },
  created() {
    this._getDiplomado();
  },
  watch: {
    $route(a, b) {
      this._getDiplomado();
    },
  },
};
</script>
<style lang="scss">
$primary: #963d5a;
input.is-input {
  border: 1px solid $primary !important;
  color: $primary !important;
  &::placeholder {
    color: $primary !important;
  }
}
.is-link {
  display: block !important;
  border-bottom: 1px $primary solid;
  padding: 2px 4px;
  margin-bottom: 8px;
  &::before {
    content: "⚬ ";
  }
}
</style>